import React from 'react';

const GudaComparison = () => {
    const comparisonData = [
        {
            feature: "Transaction Speed 🚀",
            guda: "Transfers done in under 90 seconds!🎯",
            others: "Usually take minutes to hours for conversions and transfers ⌛"
        },
        {
            feature: "UPI Integration",
            guda: "Pay directly with UPI QR codes—easy peasy! 😎 Users can pay vendors or individuals in fiat using crypto.",
            others: "No direct payments; users must convert to INR first—so close, yet so far! 😫"
        }
    ];

    return (
        <div className="w-full max-w-5xl mx-auto p-4 md:p-8 rounded-lg shadow-2xl relative">

            {/* Header */}
            <div className="text-center mb-8 md:mb-16 relative z-10">
                <h1 className="text-4xl md:text-5xl font-bold text-white mb-8 md:mb-12">What Sets GUDA Apart</h1>
            </div>

            {/* Desktop View */}
            <div className="hidden md:grid grid-cols-[200px_1fr_1fr] gap-0 relative z-10">
                {/* Headers */}
                <div></div>
                <div className="text-center pb-8">
                    <h2 className="text-3xl font-bold">
                        <span className="text-white">GU</span>
                        <span className="text-purple-500">DA</span>
                    </h2>
                </div>
                <div className="text-center pb-8">
                    <h2 className="text-lime-400 text-2xl font-semibold">Other Platforms</h2>
                </div>

                {/* Comparison Rows */}
                {comparisonData.map((row, index) => (
                    <React.Fragment key={`desktop-${index}`}>
                        <div className="border-t border-gray-700 p-6 text-lime-400 font-semibold text-lg border-r border-b">{row.feature}</div>
                        <div className="border-t border-gray-700 p-6 text-white text-center border-r border-b">{row.guda}</div>
                        <div className="border-t border-gray-700 p-6 text-white text-center border-b">{row.others}</div>
                    </React.Fragment>
                ))}

                {/* Vertical Lines */}
                <style jsx>{`
          .grid-cols-[200px_1fr_1fr] > div {
            position: relative;
          }
          .grid-cols-[200px_1fr_1fr] > div::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 100%;
            width: 1px;
            background-color: rgba(255, 255, 255, 0.5);
          }
          .grid-cols-[200px_1fr_1fr] > div:nth-child(3n)::before {
            display: none;
          }
        `}</style>


            </div>
            {/* Mobile View */}
            <div className="md:hidden space-y-8">
                {comparisonData.map((row, index) => (
                    <div key={`mobile-${index}`} className="border-t border-gray-700 pt-6">
                        <h3 className="text-lime-400 font-semibold text-lg mb-4">{row.feature}</h3>

                        <div className="grid grid-cols-1 gap-4">
                            {/* GUDA */}
                            <div className="bg-gray-900 rounded-lg p-4">
                                <h4 className="text-center mb-3">
                                    <span className="text-white font-bold text-xl">GU</span>
                                    <span className="text-purple-500 font-bold text-xl">DA</span>
                                </h4>
                                <p className="text-white text-center">{row.guda}</p>
                            </div>

                            {/* Other Platforms */}
                            <div className="bg-gray-900 rounded-lg p-4">
                                <h4 className="text-lime-400 text-center mb-3 font-semibold">Other Platforms</h4>
                                <p className="text-white text-center">{row.others}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default GudaComparison;