import React from "react";

function ContactSection() {
  const [result, setResult] = React.useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);
    const accessKey = process.env.YOUR_ACCESS_KEY_HERE;


    formData.append("access_key", "df77ebec-98f7-4f58-9eb7-ff0908edcdba");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };

  return (
    <div className="p-6 rounded-lg shadow-lg mx-auto max-w-lg contact-form">
      <h2 className="text-2xl font-bold text-white mb-4">Contact Us</h2>
      <form onSubmit={onSubmit} className="flex flex-col space-y-4">
        <input
          type="text"
          name="name"
          placeholder="Your Name"
          required
          className="p-2 rounded-md text-black bg-opacity-30 border-none focus:outline-none focus:ring-2 focus:ring-opacity-50"
        />
        <input
          type="email"
          name="email"
          placeholder="Your Email"
          required
          className="p-2 rounded-md text-black bg-opacity-30 border-none focus:outline-none focus:ring-2 focus:ring-opacity-50"
        />
        <textarea
          name="message"
          placeholder="Your Message"
          required
          className="p-2 rounded-md text-black bg-opacity-30 border-none focus:outline-none focus:ring-2 focus:ring-opacity-50 h-24"
        ></textarea>

        <button
          type="submit"
          className="bg-green-500 text-white font-semibold py-2 rounded-md hover:bg-green-600 transition duration-300"
        >
          Submit Form
        </button>
      </form>
      <span className="text-white mt-4">{result}</span>
    </div>
  );
}

export default ContactSection;