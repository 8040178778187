import React from 'react';

const Roadmap = () => {
  const events = [
    {
      date: "December 2024",
      title: "Seamless UPI Integration",
      description: "Bringing UPI Payments to GUDA"
    },
    {
      date: "January 2025",
      title: "Enhancing Security",
      description: "Implementing Zero-Knowledge Technology for Transactions"
    },
    {
      date: "March 2025",
      title: "Polygon Network Integration",
      description: "Expanding Our Network Capabilities"
    },
    {
      date: "April 2025",
      title: "Ethereum Network Integration",
      description: "Strengthening Connectivity and Utility"
    },
    {
      date: "July 2025",
      title: "Beta Launch",
      description: "Unveiling GUDA: The Future of Crypto Payments"
    }
  ];

  return (
    <div className="text-white p-8">
      <h1 className="text-4xl font-bold text-center mb-12">Roadmap</h1>
      <div className="relative max-w-4xl mx-auto">
        <div className="absolute left-1/2 transform -translate-x-1/2 h-full border-l-4 border-gray-600"></div>
        {events.map((event, index) => (
          <div key={index} className="flex items-center mb-8">
            {index % 2 === 0 ? (
              <>
                <div className="w-1/2 text-right pr-8">
                  <h2 className="text-xl font-bold text-lime-400">{event.date}</h2>
                </div>
                <div className="relative flex flex-col items-center">
                  <span className="bg-white w-6 h-6 rounded-full border-4 border-black"></span>
                  <span className="absolute left-full top-1/2 w-8 h-px bg-white"></span>
                </div>
                <div className="w-1/2 pl-8">
                  <h2 className="text-xl font-bold">{event.title}</h2>
                  <p>{event.description}</p>
                </div>
              </>
            ) : (
              <>
                <div className="w-1/2 pr-8">
                  <h2 className="text-xl font-bold">{event.title}</h2>
                  <p>{event.description}</p>
                </div>
                <div className="relative flex flex-col items-center">
                  <span className="bg-white w-6 h-6 rounded-full border-4 border-black"></span>
                  <span className="absolute right-full top-1/2 w-8 h-px bg-white"></span>
                </div>
                <div className="w-1/2 text-left pl-8">
                  <h2 className="text-xl font-bold text-lime-400">{event.date}</h2>
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Roadmap;