import React from 'react';
import { ArrowLeftRight } from 'lucide-react';
import cryptoimage from '../assets/crypto.png';
import fiatimage from '../assets/fiat.png';

const FeaturesSection = () => {
    const features = [
        {
            id: 'onramp',
            position: 'top-left',
            title: (
                <>
                    <span className="text-lime-400">Instant On-Ramp</span>
                    <br />
                    <span className="text-lime-400">& Off-Ramp</span>
                </>
            ),
            description: "Easily move between fiat and crypto in less than 90 seconds. Whether you're converting your money to crypto or turning digital assets back into fiat, GUDA streamlines the process to ensure swift, hassle-free transactions every time."
        },
        {
            id: 'network',
            position: 'top-right',
            title: (
                <>
                    <span className="text-lime-400">Multiple</span>
                    <br />
                    <span className="text-lime-400">Network Support</span>
                </>
            ),
            description: "GUDA is designed to integrate with a variety of blockchain networks, providing you with flexibility and choice in your crypto transactions."
        },
        {
            id: 'fiat',
            position: 'bottom-left',
            title: (
                <>
                    <span className="text-lime-400">Diverse</span>
                    <br />
                    <span className="text-lime-400">Fiat Options</span>
                </>
            ),
            description: "GUDA offers multiple fiat payment methods, including UPI and bank transfers, ensuring you can choose the option that best fits your needs for hassle-free transactions."
        },
        {
            id: 'fees',
            position: 'bottom-right',
            title: (
                <>
                    <span className="text-lime-400">Low</span>
                    <br />
                    <span className="text-lime-400">Transaction Fees</span>
                </>
            ),
            description: "GUDA ensures cost-effective transactions with minimal fees. Whether you're converting crypto to fiat or vice versa, our platform offers one of the most competitive fee structures, so you can maximize the value of your assets."
        }
    ];

    return (
        <section className="py-20 px-4 relative min-h-screen">
            <div className="container mx-auto relative">
                {/* Title */}
                <h2 className="text-6xl font-bold text-center mb-24 text-white">
                    Features
                </h2>

                {/* Mobile Crypto/Fiat Connection */}
                <div className="md:hidden mb-12 flex items-center justify-center space-x-2">
                    <img src={cryptoimage} alt="Crypto icons" className="w-16 h-16" />
                    <ArrowLeftRight className="text-lime-400 w-6 h-6 animate-pulse" />
                    <div className="flex flex-col items-center">
                        <span className="text-2xl font-bold">
                            <span className="text-white">GU</span>
                            <span className="text-purple-500">DA</span>
                        </span>
                    </div>
                    <ArrowLeftRight className="text-lime-400 w-6 h-6 animate-pulse" />
                    <img src={fiatimage} alt="Fiat icons" className="w-16 h-16" />
                </div>

                {/* Features Layout */}
                <div className="relative">
                    {/* Features Grid */}
                    <div className="grid md:grid-cols-2 gap-8 md:gap-24 relative">
                        {features.map((feature) => (
                            <div
                                key={feature.id}
                                className={`p-6 bg-gray-800/30 backdrop-blur-sm rounded-lg border border-gray-700 
                  ${feature.position.includes('right') ? 'md:translate-x-12' : 'md:-translate-x-12'}
                  transition-all duration-300 hover:border-lime-400 hover:bg-gray-800/50 hover:scale-105 relative z-30`}
                            >
                                <div className="mb-4">
                                    <h3 className="text-2xl font-bold text-center">{feature.title}</h3>
                                </div>
                                <p className="text-gray-300 text-sm text-center">{feature.description}</p>
                            </div>
                        ))}
                    </div>

                    {/* Crypto/Fiat Connection Layer */}
                    <div className="absolute inset-x-1/4 top-1/2 -translate-y-1/2 hidden md:block z-20">
                        {/* Crypto Side */}
                        <div className="absolute left-0 -translate-y-1/2">
                            <img src={cryptoimage} alt="Crypto icons" className="w-32 h-32" />
                        </div>

                        {/* Fiat Side */}
                        <div className="absolute right-0 -translate-y-1/2">
                            <img src={fiatimage} alt="Fiat icons" className="w-32 h-32" />
                        </div>

                        {/* Horizontal Connection */}
                        <div className="absolute left-[15%] right-[15%] top-1/2 -translate-y-1/2 h-px bg-white shadow-glow">
                            {/* Left Arrow */}
                            <div className="absolute left-[10%] -translate-y-1/2 bg-black p-2 rounded-full border border-white shadow-glow">
                                <ArrowLeftRight className="text-white w-6 h-6 animate-pulse" />
                            </div>
                            {/* Right Arrow */}
                            <div className="absolute right-[10%] -translate-y-1/2 bg-black p-2 rounded-full border border-white shadow-glow">
                                <ArrowLeftRight className="text-white w-6 h-6 animate-pulse" />
                            </div>
                        </div>
                    </div>

                     {/* Center GUDA Logo */}
                     <div className='hidden md:block'>
                     <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-32 h-32 bg-gray-800/80 backdrop-blur-sm flex items-center justify-center rounded-lg border border-lime-400/50 hover:border-lime-400 transition-all duration-300 hover:scale-110 z-20">
                        <span className="text-3xl font-bold">
                            <span className="text-white">GU</span>
                            <span className="text-purple-500">DA</span>
                        </span>
                    </div>
                    </div>

                </div>
            </div>
        </section>
    );
};

export default FeaturesSection;
