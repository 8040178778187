import React from 'react';
import gudaImage from '../assets/GUDA.png';

const Header = () => {
    return (
        <header className="fixed top-0 left-0 right-0 p-4 z-50 flex justify-center">
            {/* Desktop Header */}
            <div className="hidden md:flex flex-col items-center glassmorphic-bg px-6 py-4 rounded-full">
                <a href="#home" className="flex flex-col items-center">
                    <img 
                        src={gudaImage} 
                        alt="GUDA Logo" 
                        className="w-12 h-12 mb-2" 
                        style={{ transform: 'scale(2)' }} 
                    />
                    <span className="text-lg glowing-text text-center">
                        Global Utility of <span className="text-purple-500">Digital Assets</span>
                    </span>
                </a>
            </div>

            {/* Mobile Header */}
            <div className="flex md:hidden flex-col items-center glassmorphic-bg px-4 py-2 rounded-full">
                <a href="#home" className="flex flex-col items-center">
                    <img 
                        src={gudaImage} 
                        alt="GUDA Logo" 
                        className="w-10 h-10 mb-1" 
                        style={{ transform: 'scale(2)' }} 
                    />
                    <span className="text-sm glowing-text text-center">
                        Global Utility of <span className="text-purple-500">Digital Assets</span>
                    </span>
                </a>
            </div>
        </header>
    );
};

export default Header;
